













import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import TabBase from '@/components/atoms/TabBase.vue'
import SchoolCurriculumInformation from '@/components/organisms/SchoolCurriculumInformation.vue'
import ModalSetSchoolCurriculum from '@/components/organisms/ModalSetSchoolCurriculum.vue'
import { TEACHER_SETTING_TEXT } from '@/constants'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    TabBase,
    SchoolCurriculumInformation,
    ModalSetSchoolCurriculum,
  },
})
export default class SchoolCurriculum extends Vue {
  private isV3 = Vue.prototype.$gdlsCookiesV3.isV3()
  private breadcrumbs = [
    { text: this.isV3 ? TEACHER_SETTING_TEXT : '設定', href: '/teacher/setting/top' },
    { text: '学校設定', href: '/teacher/setting/school' },
    { text: '', active: true },
  ]

  private tabBaseDatas: { id: number; name: string; gradeIds: any }[] = []

  private isActiveTab = 0

  private gradeDatas: { grade: any; items: any }[][] = []

  @Ref() modalSetSchoolCurriculum!: ModalSetSchoolCurriculum

  private showSchoolCurriculum(tableIndex: number, rowIndex: number): void {
    const grade = this.gradeDatas[this.isActiveTab][tableIndex].grade
    const item = this.gradeDatas[this.isActiveTab][tableIndex].items[rowIndex]
    this.modalSetSchoolCurriculum.setPublisherCurriculum(
      item.subject,
      item.schoolCurriculumId,
      this.schoolId,
      grade.id,
      item.subjectId,
      item.publisherCurriculumId
    )
    this.modalSetSchoolCurriculum.show()
  }

  private async mounted() {
    await this.loadTabBaseDatas()
    await this.loadDatas()
  }

  @Prop()
  private schoolId!: number

  private async loadTabBaseDatas() {
    await Vue.prototype.$http.httpWithToken.get('/grade_groups').then((res: any) => {
      this.tabBaseDatas = res.data.map((gradeGroup: { name: string; grades: { id: number }[] }, index: number) => {
        return {
          id: index,
          name: gradeGroup.name,
          gradeIds: gradeGroup.grades.map((grade: { id: number }) => {
            return grade.id
          }),
        }
      })
    })
    this.gradeDatas = Array(this.tabBaseDatas.length)
    this.gradeDatas.fill([])
  }

  private async loadDatas() {
    const gradeIds = this.tabBaseDatas[this.isActiveTab] ? this.tabBaseDatas[this.isActiveTab].gradeIds : []
    await Vue.prototype.$http.httpWithToken
      .get('/school_curriculums', {
        params: { schoolId: this.schoolId, gradeId: gradeIds },
      })
      .then((res: any) => {
        this.breadcrumbs[this.breadcrumbs.length - 1].text = res.data.schoolName + '(教科書・カリキュラム)'
        this.gradeDatas.splice(
          this.isActiveTab,
          1,
          res.data.grades.map((grade: { gradeId: number; gradeName: string; settings: any }) => {
            return {
              grade: { id: grade.gradeId, name: grade.gradeName },
              items: grade.settings.map(
                (setting: {
                  schoolCurriculumId: number | null
                  publisherCurriculumId: number | null
                  subjectId: number
                  subjectName: string
                  publisherName: string
                  curriculumName: string
                }) => {
                  return {
                    schoolCurriculumId: setting.schoolCurriculumId,
                    publisherCurriculumId: setting.publisherCurriculumId,
                    subjectId: setting.subjectId,
                    subject: setting.subjectName,
                    publisher: setting.publisherName ? setting.publisherName : '未実施',
                    curriculum: setting.curriculumName ? setting.curriculumName : '未実施',
                    action: [{ onclick: this.showSchoolCurriculum, name: '編集' }],
                  }
                }
              ),
            }
          })
        )
      })
  }

  @Watch('isActiveTab')
  private isActiveTabChange(val: number, oldVal: number) {
    this.loadDatas()
  }
}
