














import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    TableBase,
  },
})
export default class SchoolCurriculumInformation extends Vue {
  private tableBasePerPage: number | null = null

  private tableBaseFields: object[] = [
    { key: 'subject', label: '教科' },
    { key: 'publisher', label: '教科書' },
    { key: 'curriculum', label: 'カリキュラム' },
    { key: 'action', label: '' },
  ]

  private tableBaseButtons: string[] = ['action']

  @Prop()
  gradeDatas!: []
}
