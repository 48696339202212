





































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
    CheckboxSquare,
    ModalBase,
  },
})
export default class ModalSetSchoolCurriculum extends Mixins(ModalBaseMethod) {
  private defaultOptionDatas = [{ value: 0, text: '未実施' }]

  private publisherOptionDatas: { value: number; text: string; curriculums: any }[] = []

  private publisherSelectedData = 0

  private get curriculumOptionDatas(): any {
    const publisher = this.publisherOptionDatas.find(
      (publisher: { value: number }) => publisher.value === this.publisherSelectedData
    )
    return publisher ? publisher.curriculums : []
  }

  private curriculumSelectedData = 0

  private gradeId = 0

  private subjectId = 0

  private subjectName = ''

  private schoolId = 0

  private schoolCurriculumId: number | null = null

  public async setPublisherCurriculum(
    pSubjectName: string,
    pSchoolCurriculumId: number,
    pSchoolId: number,
    pGradeId: number,
    pSubjectId: number,
    pPublisherCurriculumId: number
  ) {
    this.subjectName = pSubjectName
    this.schoolCurriculumId = pSchoolCurriculumId
    this.gradeId = pGradeId
    this.subjectId = pSubjectId
    this.schoolId = pSchoolId

    await this.loadDatas()

    let selectedPublisher = null
    if (pPublisherCurriculumId) {
      selectedPublisher = this.publisherOptionDatas.find((publisher: { curriculums: any }) => {
        return publisher.curriculums.some(
          (curriculum: { value: number }) => curriculum.value === pPublisherCurriculumId
        )
      })
    }
    if (selectedPublisher) {
      this.publisherSelectedData = selectedPublisher.value
      this.curriculumSelectedData = pPublisherCurriculumId
    } else {
      this.publisherSelectedData = this.publisherOptionDatas[0]?.value || 0
      this.curriculumSelectedData = this.publisherOptionDatas[0]?.curriculums[0]?.value || 0
    }
  }

  private async loadDatas() {
    await Vue.prototype.$http.httpWithToken
      .get('/publisher_curriculums', { params: { gradeId: this.gradeId, subjectId: this.subjectId } })
      .then((res: any) => {
        this.publisherOptionDatas = res.data.map(
          (publisher: { publisherId: number; publisherName: string; curriculums: any }) => {
            return {
              value: publisher.publisherId,
              text: publisher.publisherName,
              curriculums: publisher.curriculums.map((curriculum: { id: number; name: string }) => {
                return {
                  value: curriculum.id,
                  text: curriculum.name,
                }
              }),
            }
          }
        )
      })
  }

  /**
   * リクエスト可能フラグ
   */
  private get isRequestable(): boolean {
    return this.publisherOptionDatas.length != 0
  }

  /**
   * 未受講ボタンの色（ボタン操作制御）
   */
  private get destroyColortype(): string {
    return this.schoolCurriculumId && this.isRequestable ? 'white' : 'pointer-events-none'
  }

  /**
   * 保存ボタンの色（ボタン操作制御）
   */
  private get submitColortype(): string {
    return this.isRequestable ? 'blue' : 'pointer-events-none'
  }

  private async submit(): Promise<void> {
    if (this.schoolCurriculumId) {
      // 更新
      await Vue.prototype.$http.httpWithToken
        .put(`/school_curriculums/${this.schoolCurriculumId}`, { publisherCurriculumId: this.curriculumSelectedData })
        .then(() => {
          this.hide()
          this.$emit('loadDatas')
        })
        .catch((error: any) => {
          if (error.response.data.status === 404) {
            alert('データが見つかりません。ページを更新してお確かめください。')
          }
        })
    } else {
      // 新規登録
      await Vue.prototype.$http.httpWithToken
        .post('/school_curriculums/', {
          schoolId: this.schoolId,
          gradeId: this.gradeId,
          subjectId: this.subjectId,
          publisherCurriculumId: this.curriculumSelectedData,
        })
        .then(() => {
          this.hide()
          this.$emit('loadDatas')
        })
        .catch((error: any) => {
          if (error.response.data.status === 409) {
            alert('重複しているため登録できません。ページを更新してお確かめください。')
          }
        })
    }
  }

  private async destroy(): Promise<void> {
    if (!confirm('未受講に設定しますか？')) return

    await Vue.prototype.$http.httpWithToken
      .delete(`/school_curriculums/${this.schoolCurriculumId}`)
      .then(() => {
        this.hide()
        this.$emit('loadDatas')
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
  }

  @Watch('curriculumOptionDatas', { deep: true })
  private curriculumOptionDatasChange(val: any, oldVal: any) {
    if (val.length === 0) return
    this.curriculumSelectedData = val[0].value
  }
}
